<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="highlighted-color1">
        <span>{{ $t('my_company') }}</span>
      </h1>
    </div>
    <!-- COMPANY -->
    <div class="mt-4">
      <div class="card" v-if="user">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div>
              <div class="d-flex align-items-center mb-3">
                <div class="company-logo me-3" :style="{
                  backgroundImage: user.company.logo ? `url('${user.company.logo}')` : false
                }"></div>
                <h2 class="mb-0">
                  {{ user.company.fantasy_name }}
                </h2>
              </div>
              <p>
                {{ $t('company_name') }}: {{ user.company.name }}<br/>
                <template v-if="user.company.country === 'BR'">
                  {{ $t('company_personal_id') }}:
                  <template v-if="user.company.document.length === 11">
                    {{ user.company.document | VMask('###.###.###-##') }}
                  </template>
                  <template v-else>
                    {{ user.company.document | VMask('##.###.###/####-##') }}
                  </template>
                  <br/>
                  {{ $t('address') }}: <span v-if="user.company.address">{{`${user.company.address.street}, ${user.company.address.number}${user.company.address.complement ? ' - ' + user.company.address.complement : ''} - ${user.company.address.city}, ${user.company.address.state}`}} - {{ $t('zipcode') }}: {{ user.company.address.zipcode | VMask('#####-###') }}</span>
                </template>
                <template v-else>
                  {{ $t('company_tax_id') }}: {{ user.company.document }}
                </template><br/>
                {{ $t('country') }}: {{ $t(`countries_names.${user.company.country}`) }}
              </p>
            </div>
            <div>
              <Button class="btn btn-primary" v-b-modal.edit-company-modal>{{ $t('edit_company') }}</Button>
            </div>
          </div>
        </div>
        <EditCompanyModal
          :company="user.company"
          @company-updated="getCompany"
        />
      </div>
      <div v-else class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
    </div> <!-- END COMPANY -->
    <!-- USERS -->
    <div class="mt-4">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h2 class="mb-0 highlighted-color1">
            <span>{{ $t('registered_users') }}</span>
          </h2>
        </div>
        <div>
          <Button
            class="btn btn-primary"
            @click="openNewUserModal"
          >
            {{ $t('register_new_user') }}
          </Button>
        </div>
      </div>
      <div class="mt-4">
        <div v-if="usersLoader" class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
        <div v-else>
          <div class="card" v-if="user">
            <div class="card-body">
              <!-- FILTERS -->
              <div class="d-flex align-items-center">
                <span class="me-4">{{ $t('filters') }}</span>
                <div class="d-flex justify-content-center align-items-center default-form">
                  <span class="fw-bold form-label me-3 mb-0">{{ $t('status') }}:</span>
                  <div class="custom-select custom-select-sm">
                    <select class="form-control form-control-sm" v-model="filters.status">
                      <option value="">{{ $t('all') }}</option>
                      <option value="ACTIVE">{{ $t('status_type.active') }}</option>
                      <option value="INACTIVE">{{ $t('status_type.inactive') }}</option>
                    </select>
                  </div>
                </div>
              </div> <!-- END FILTERS -->
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>{{ $t('name') }}</th>
                    <th>{{ $t('user_type') }}</th>
                    <th>{{ $t('email') }}</th>
                    <th>{{ $t('email_verified') }}</th>
                    <th>{{ $t('two_factor_authentication') }}</th>
                    <th>{{ $t('status') }}</th>
                    <th width="100">
                      {{ $t('details') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(accountUser, index) in userList" :key="index">
                    <td>{{ accountUser.name }}</td>
                    <td>{{ accountUser.role.slug | formatUserRole }}</td>
                    <td>{{ accountUser.email }}</td>
                    <td>{{ accountUser.is_email_verified ? $t('yes') : $t('no') }}</td>
                    <td>{{ accountUser.use_2fa ? $t('yes') : $t('no') }}</td>
                    <td>{{ accountUser.is_active ? $t('status_type.active') : $t('status_type.inactive') }}</td>
                    <td>
                      <template v-if="accountUser.id === user.id">
                        <span>
                          ---
                        </span>
                      </template>
                      <template v-else>
                        <a href="#" @click.prevent="openEditUserModal(accountUser)" class="me-2"  v-b-tooltip.hover :title="$t('edit')">
                          <i class="fa fa-pencil"></i>
                        </a>
                        <a href="#" @click.prevent="openDestroyUserModal(accountUser)" v-if="accountUser.is_active"  v-b-tooltip.hover :title="$t('disable')">
                          <i class="fa fa-trash"></i>
                        </a>
                        <a href="#" @click.prevent="openActivateUserModal(accountUser)" v-b-tooltip.hover :title="$t('enable')" v-else>
                          <i class="fa fa-check"></i>
                        </a>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <UpsertUserModal
              :user="selectedUser"
              @user-created="getUsers"
              @user-updated="getUsers"
            />
            <ActivateUserModal
              :user="selectedUser"
              @user-activated="getUsers"
            />
            <DestroyUserModal
              :user="selectedUser"
              @user-destroyed="getUsers"
            />
          </div>
        </div>
      </div>
    </div> <!-- END USERS -->
  </div>
</template>

<script>

import UsersService from '@/modules/users/services/users-service'
import { mapState } from 'vuex'

import ActivateUserModal from '@/components/users/ActivateUserModal'
import Button from '@/components/common/Button/Button'
import DestroyUserModal from '@/components/users/DestroyUserModal'
import EditCompanyModal from '@/components/companies/EditCompanyModal'
import Loader from '@/components/common/Loader/Loader'
import UpsertUserModal from '@/components/users/UpsertUserModal'

export default {
  components: {
    ActivateUserModal,
    Button,
    DestroyUserModal,
    EditCompanyModal,
    Loader,
    UpsertUserModal
  },
  data () {
    return {
      users: [],
      usersLoader: false,
      selectedUser: null,
      filters: {
        status: 'ACTIVE'
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('my_company')
    }
  },
  created () {
    if (this.user) {
      this.checkIfUserIsManager()
      this.getUsers()
    }
  },
  watch: {
    user (newUser) {
      this.checkIfUserIsManager()
      this.getUsers()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Parsed user list
     */
    userList () {
      return this.users.filter(item => {
        if (this.filters.status === '') return true
        else if (this.filters.status === 'ACTIVE') return item.is_active
        else if (this.filters.status === 'INACTIVE') return !item.is_active
        return []
      })
    }
  },
  methods: {
    /**
     * Check if user is manager or not
     */
    checkIfUserIsManager () {
      if (this.user.role.slug !== 'user-manager' && this.user.role.slug !== 'administrator') this.$router.push('/')
    },
    /**
     * Get company after update
     */
    async getCompany () {
      await UsersService.me()
    },
    /**
     * Get users associated with the company
     */
    async getUsers () {
      this.usersLoader = true
      this.filters.status = 'ACTIVE'
      this.users = []
      try {
        const users = await UsersService.getUsers({ companyId: this.user.company.id })
        this.users = users.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.usersLoader = false
    },
    /**
     * Open modal to activate user
     */
    openActivateUserModal (user) {
      this.selectedUser = user
      this.$bvModal.show('activate-user-modal')
    },
    /**
     * Open modal to destroy user
     */
    openDestroyUserModal (user) {
      this.selectedUser = user
      this.$bvModal.show('destroy-user-modal')
    },
    /**
     * Open modal to edit user
     */
    openEditUserModal (user) {
      this.selectedUser = user
      this.$bvModal.show('upsert-user-modal')
    },
    /**
     * Open modal to create user
     */
    openNewUserModal () {
      this.selectedUser = null
      this.$bvModal.show('upsert-user-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.company-logo {
  width: 50px;
  height: 50px;
  background-color: #eee;
  background-position: center;
  background-size: cover;
  -webkit-box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 3px 0 rgba(0,0,0,0.1);
}
</style>
