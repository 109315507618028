import { render, staticRenderFns } from "./CompanyShow.vue?vue&type=template&id=2a4d3143&scoped=true&"
import script from "./CompanyShow.vue?vue&type=script&lang=js&"
export * from "./CompanyShow.vue?vue&type=script&lang=js&"
import style0 from "./CompanyShow.vue?vue&type=style&index=0&id=2a4d3143&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a4d3143",
  null
  
)

export default component.exports